*{
  padding:0;
  margin:0;
  box-sizing:border-box;
}

body{
  height:100%;
  width:100%;
  overflow-x:hidden;
  font-family: 'Source Sans Pro', sans-serif;
}

/*html, body{overflow:hidden;}*/

html{
  scroll-behavior: smooth;
}

.filler{
  height:50px;
}

.App{
  height:100%;
  position:relative;
}